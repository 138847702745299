import { WithProofStatus } from "@/components/molecules/DesignApproval/types";
import { ProofWithTemplate } from "@/components/templates/ProofTemplate";
import { any, compose, findIndex, pathEq, prop } from "ramda";

export const getAllowedFiles = (proof: ProofWithTemplate) => {
  const allowedFileIds = proof?.template?.filePurposes.map((fp) => fp.id);

  return proof.files.filter((file) => {
    if (allowedFileIds.includes(file.purposes[0].id)) {
      return true;
    }

    return false;
  });
};

export const hasProofWithId = (proofId) => {
  return compose(any(pathEq(proofId, ["id"])), prop("proofs"));
};

export const findLineItemIndexByProofId = (proofId, lineItems) => {
  return findIndex(hasProofWithId(proofId), lineItems);
};

export const findNextNotSentLineItem = (
  lineItems: WithProofStatus[],
  currentProofId: string
): any | undefined => {
  // Loop through each lineItem in the order
  for (const lineItem of lineItems) {
    // Get the first proof of the line item
    const proof = lineItem.proofs[0];

    // Check if the proofStatus is 'not-sent' and the proof id is not the current proof id

    const slugs = ["not-sent", "sent"];
    if (
      slugs.includes(lineItem.proofStatus.slug) &&
      proof.id !== currentProofId
    ) {
      return lineItem;
    }
  }

  // If no matching lineItem is found, return null
  return null;
};

export const allProofsAccepted = (lineItems?: WithProofStatus[]): boolean => {
  if (!lineItems) {
    return false;
  }

  const allStatusesApproved = lineItems
    .map((lineItem) => {
      return lineItem.proofStatus?.slug;
    })
    .every((status) => status === "approved");

  return allStatusesApproved;
};

export const findNextProofId = (
  order: any,
  currentProofId: string
): string | undefined => {
  if (!order) {
    return undefined;
  }

  const foundNextLineItem = findNextNotSentLineItem(
    order.lineItems,
    currentProofId
  );

  if (!foundNextLineItem) {
    return undefined;
  }

  const nextProofId = foundNextLineItem.proofs.at(0).id;

  if (nextProofId === currentProofId) {
    // Same as this proof
    return undefined;
  }

  return nextProofId.replace("gid://glide/Proof/", "");
};
