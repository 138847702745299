import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "next-i18next";
import React from "react";

import { IStep } from "@/types";

export interface IStartProductionProps {
  disabled?: boolean;
  onStartProductionClick: () => void;
  onChangeStep: (step: IStep) => void;
  proofImage: string;
}

const StartProduction: React.FC<IStartProductionProps> = ({
  onStartProductionClick,
  onChangeStep,
  proofImage,
}: IStartProductionProps) => {
  const { t } = useTranslation("DesignApproval");

  return (
    <Card>
      <CardContent>
        <Stack gap={2}>
          <Typography variant="h4" component="div" align="center">
            {t("production.title")}
          </Typography>
          <Typography variant="body2" component="div" align="center">
            {t("production.message")}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={onStartProductionClick}
            >
              {t("production.startProduction")}
            </Button>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default StartProduction;
