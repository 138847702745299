import { IStep } from "@/types";
import { getCorkscrewBaseUrl } from "@/utils/config";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { StyledReviewWrapper } from "../molecules/DesignApproval/Review";

interface IDesignProps {
  onChangeStep?: (step: IStep) => void;
  proofImage: string;
  allApproved: boolean;
}

// const useStyles = makeStyles((theme: Theme) => ({
//   checkedIcon: {
//     fill: '#62CD41',
//     width: 61,
//     height: 61,
//     marginRight: theme.spacing(2),
//     [theme.breakpoints.down('xs')]: {
//       width: 44,
//       height: 44,
//       marginRight: theme.spacing(1),
//     },
//   },
//   reviewDesignTopSection: {
//     marginBottom: theme.spacing(4),
//     marginTop: theme.spacing(4),
//     [theme.breakpoints.down('xs')]: {
//       marginBottom: theme.spacing(2),
//       marginTop: theme.spacing(2),
//     },
//   },
//   approvedTypo: {
//     fontSize: 50,
//     [theme.breakpoints.down('xs')]: {
//       fontSize: theme.typography.pxToRem(35),
//     },
//   },
//   button: {
//     background: theme.colors.coolBlue,
//     color: theme.colors.white,
//     width: 326,
//     height: 75,
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     padding: 0,
//     fontSize: theme.typography.pxToRem(18),
//     '&:hover': {
//       background: theme.colors.coolBlueDark,
//     },
//     marginBottom: theme.spacing(6),
//   },
//   imageWrapper: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
//   imageContainer: {
//     // width: 462,
//     // height: 329,
//     marginBottom: theme.spacing(8),
//     [theme.breakpoints.down('xs')]: {
//       // width: 360,
//       // height: 256.75,
//     },
//   },
//   proofImage: {
//     maxHeight: 329,
//     [theme.breakpoints.down('xs')]: {
//       maxHeight: 200,
//     },
//     // width: '100%',
//     // height: '100%',
//   },
//   heroTypo: {
//     maxWidth: '90%',
//     margin: '0 auto',
//     marginBottom: theme.spacing(8),
//   },
//   heroTypoTitle: {
//     marginBottom: theme.spacing(2),
//     fontSize: theme.typography.pxToRem(26),
//   },
//   heroTypoSubtitle: {
//     fontSize: theme.typography.pxToRem(16),
//   },
// }))

const ReviewDesign: React.FunctionComponent<IDesignProps> = ({
  proofImage,
  allApproved,
}: IDesignProps) => {
  const router = useRouter();
  const { t } = useTranslation("DesignApproval");
  const corkscrewBaseUrl = getCorkscrewBaseUrl();

  return (
    <StyledReviewWrapper>
      <Stack gap={2} alignItems="center">
        <Stack direction="row">
          <CheckCircleOutlinedIcon />
          <Typography component="p" ml={1}>
            {t("approvedDesign.approved")}
          </Typography>
        </Stack>
        <Typography variant="h5">{t("approvedDesign.title")}</Typography>
        <Typography>{t("approvedDesign.message")}</Typography>

        {allApproved ? (
          <Link href={`/orders/${router.query.id}`}>Order approval</Link>
        ) : null}
      </Stack>
    </StyledReviewWrapper>
  );
};

export default ReviewDesign;
