import { Card, CardContent, Stack, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import React from "react";

export interface IPrintingProps {}

const Printing: React.FC<IPrintingProps> = ({}: IPrintingProps) => {
  const { t } = useTranslation("DesignApproval");

  return (
    <Card>
      <CardContent>
        <Stack gap={2}>
          <Typography variant="h4" component="div" align="center">
            Printing
          </Typography>
          <Typography variant="body2" component="div" align="center">
            {t("production.message")}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Printing;
