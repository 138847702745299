import theme from "@/utils/theme";
import type { IPublicUserGetMeResponse } from "@kartdavid/corkscrew-types/packages/api-types/public-api/api_types_public_api";
import { Box, Button, Divider, ImageList, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "next-i18next";
import { default as NextLink } from "next/link";
import React, { Fragment, useEffect, useState } from "react";

import { useUser } from "@/contexts/userContext";
import {
  EModalActions,
  IPayment,
  MailingAddressInput,
  MaterialType,
  PublicApprovedArtwork,
} from "@/types";
import routes from "@/utils/routes";

import { OrderListEdge } from "@/api/corkcrew";
import { LoadingView, UserAddressCard, UserPaymentCard } from "../atoms";
import { OrderListTable } from "../molecules";
import DesignItem from "./DesignsView/DesignItem";

/* Styling */
const Wrapper = styled("div")(() => ({
  marginBottom: theme.spacing(2),
}));

const SectionHeaderTypography = styled(Typography)(() => ({
  fontSize: theme.typography.pxToRem(26),
  marginBottom: theme.spacing(1),
  fontWeight: 500,
}));

const RestyledButton = styled(Button)(() => ({
  marginTop: theme.spacing(2),
  border: `2px solid ${theme.colors.coolBlue}`,
  ":hover": {
    border: `2px solid ${theme.colors.coolBlue}`,
  },
  fontWeight: theme.typography.fontWeightRegular,
  minWidth: 150,
}));

/* End of styling */

export type MyDetailsProps = {
  allMaterials: { data: MaterialType[] };
  isFetchingOrders: boolean;
  hasMoreOrders: boolean;
  designs: PublicApprovedArtwork[];
  orders: OrderListEdge[];
  userPaymentMethods: IPayment[];
  userAddressesState: MailingAddressInput[];
  userActions: {
    addAddress: (
      address: MailingAddressInput,
      handleActionChange: () => void
    ) => void;
    removeAddress: (address: MailingAddressInput) => void;
    editAddress: (
      address: MailingAddressInput,
      handleActionChange: () => void
    ) => void;
    setDefaultAddress: (address: MailingAddressInput) => void;
  };
  handleEditButtonClick: (addr: MailingAddressInput) => void;
  handleClick: (modalAction: EModalActions) => void;
  addresses: any[];
};

const features = {
  payments: false,
  designs: true,
  preferences: false,
  addresses: true,
};

const MyDetails: React.FC<MyDetailsProps> = ({
  allMaterials,
  isFetchingOrders,
  hasMoreOrders,
  userAddressesState,
  designs,
  orders,
  userPaymentMethods,
  userActions,
  handleEditButtonClick,
  handleClick,
  addresses,
}) => {
  const { t, ready } = useTranslation("AccountSummary");
  const { t: tAddressList } = useTranslation("AddressList");
  const {
    state: { profile, canDownloadInvoice },
  } = useUser();

  const [myDetails, setMyDetails] = useState<
    IPublicUserGetMeResponse | undefined
  >(undefined);
  const ordersPagePath = routes.orders;

  useEffect(() => {
    if (!profile && !ready) return;

    setMyDetails(profile);
  }, [profile, ready]);

  return (
    <Fragment>
      {myDetails ? (
        <Box>
          <Box
            sx={{
              fontWeight: theme.typography.fontWeightLight,
            }}
          >
            <Box>
              {myDetails.firstName ? (
                <Typography
                  sx={{
                    fontSize: { xs: "1.25rem", sm: "2rem", fontWeight: 500 },
                  }}
                >
                  {myDetails.firstName} {myDetails.lastName}
                </Typography>
              ) : null}
              {myDetails.companyName ? (
                <Typography sx={{ fontSize: 1.3 }}>
                  {myDetails.companyName}
                </Typography>
              ) : null}

              <Typography
                sx={{
                  paddingTop: 0.4,
                  paddingBottom: 0.4,
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              >
                {myDetails.email}
              </Typography>
              {myDetails.phone ? (
                <Typography>{myDetails.phone}</Typography>
              ) : null}
            </Box>
          </Box>
          <Box sx={{ marginTop: 2, marginBottom: 2 }}>
            <SectionHeaderTypography>
              {t("titles.latestOrders")}
            </SectionHeaderTypography>

            {isFetchingOrders === false ? (
              <OrderListTable
                orders={orders}
                ordersPagePath={ordersPagePath}
                canDownloadInvoice={canDownloadInvoice}
              />
            ) : null}
            {hasMoreOrders ? (
              <Box>
                <NextLink href={ordersPagePath}>
                  <RestyledButton variant="outlined">
                    {t("buttons.seeMore")}
                  </RestyledButton>
                </NextLink>
              </Box>
            ) : null}
          </Box>
          <Box>
            {!features.addresses ? null : (
              <Box sx={{ mt: 1 }}>
                <Wrapper>
                  <SectionHeaderTypography>
                    {t("titles.address")}
                  </SectionHeaderTypography>
                  <Box>
                    <Box
                      component="header"
                      sx={{
                        p: 1.5,
                        display: { xs: "none", sm: "flex" },
                        gap: "0 6px",
                        border: {
                          xs: "none",
                          md: `1px solid ${theme.colors.greyPlaceholder}`,
                        },
                      }}
                    >
                      <Box sx={{ flex: 1, display: "flex" }}>
                        <Typography
                          sx={{ fontWeight: 600, textTransform: "uppercase" }}
                        >
                          {tAddressList("name")}
                        </Typography>
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          sx={{ fontWeight: 600, textTransform: "uppercase" }}
                        >
                          {tAddressList("address")}
                        </Typography>
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          sx={{ fontWeight: 600, textTransform: "uppercase" }}
                        >
                          {tAddressList("secondaryAddress")}
                        </Typography>
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          sx={{ fontWeight: 600, textTransform: "uppercase" }}
                        >
                          {tAddressList("city")}
                        </Typography>
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          sx={{ fontWeight: 600, textTransform: "uppercase" }}
                        >
                          {tAddressList("zip")}
                        </Typography>
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          sx={{ fontWeight: 600, textTransform: "uppercase" }}
                        >
                          {tAddressList("phone")}
                        </Typography>
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          sx={{ fontWeight: 600, textTransform: "uppercase" }}
                        >
                          {tAddressList("actions")}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        borderLeft: {
                          xs: "none",
                          md: `1px solid ${theme.colors.greyPlaceholder}`,
                        },
                        borderRight: {
                          xs: "none",
                          md: `1px solid ${theme.colors.greyPlaceholder}`,
                        },
                        borderBottom: {
                          xs: "none",
                          md: `1px solid ${theme.colors.greyPlaceholder}`,
                        },
                      }}
                    >
                      {addresses &&
                        addresses.length > 0 &&
                        addresses.map((address, index: number) => (
                          <UserAddressCard
                            key={index}
                            address={address}
                            handleRemove={() =>
                              userActions.removeAddress(address)
                            }
                            handleEdit={handleEditButtonClick}
                            handleSetDefault={userActions.setDefaultAddress}
                          />
                        ))}
                    </Box>
                  </Box>
                  <div>
                    <RestyledButton
                      variant="outlined"
                      onClick={() => handleClick(EModalActions.addAddress)}
                    >
                      {t("buttons.addAddress")}
                    </RestyledButton>
                  </div>
                </Wrapper>
              </Box>
            )}

            {features.payments ? (
              <Fragment>
                <Wrapper>
                  <SectionHeaderTypography>
                    {t("titles.paymentMethods")}
                  </SectionHeaderTypography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {userPaymentMethods.map((paymentMethod, index: number) => (
                      <UserPaymentCard
                        key={index}
                        payment={paymentMethod}
                        handleRemove={() => {}}
                        handleEdit={() => null}
                        handleSetDefault={() => null}
                      />
                    ))}
                  </Box>
                  <div>
                    <RestyledButton
                      variant="outlined"
                      onClick={() =>
                        handleClick(EModalActions.addPaymentMethod)
                      }
                    >
                      {t("buttons.addPaymentMethod")}
                    </RestyledButton>
                  </div>
                </Wrapper>
              </Fragment>
            ) : null}

            {features.designs && designs.length > 0 ? (
              <Box sx={{ mt: 3 }}>
                <Wrapper>
                  <SectionHeaderTypography>
                    {t("titles.designs")}
                  </SectionHeaderTypography>
                  <Box sx={{ display: { xs: "none", md: "block" } }}>
                    <ImageList cols={5} gap={12}>
                      {designs.map((design, idx) => {
                        return (
                          <DesignItem
                            key={design.proofId || idx}
                            design={design}
                            allMaterials={allMaterials}
                          />
                        );
                      })}
                    </ImageList>
                  </Box>
                  <Box sx={{ display: { xs: "block", md: "none" } }}>
                    <ImageList cols={2} gap={12}>
                      {designs.map((design, idx) => {
                        return (
                          <DesignItem
                            key={design.proofId || idx}
                            design={design}
                            allMaterials={allMaterials}
                          />
                        );
                      })}
                    </ImageList>
                  </Box>
                  {designs.length > 1 ? (
                    <Box>
                      <NextLink href="/designs">
                        <RestyledButton variant="outlined">
                          {t("buttons.seeMore")}
                        </RestyledButton>
                      </NextLink>
                    </Box>
                  ) : null}
                </Wrapper>
              </Box>
            ) : null}

            {features.preferences ? (
              <Fragment>
                <Divider sx={{ background: theme.colors.greyLight }} />
                <Box
                  sx={{
                    marginTop: theme.spacing(3),
                    marginBottom: theme.spacing(10),
                  }}
                >
                  <SectionHeaderTypography>
                    {t("titles.preferences")}
                  </SectionHeaderTypography>
                  <div>
                    {/* {preferenceItems.map((item) => (
        <PreferenceItem
          key={item.id}
          item={item}
          handleClick={() => {
            handleClick(EModalActions.preferenceLanguage);
            handleEditOrder(item.id);
          }}
          setFormData={setPreferencesFormData}
        />
      ))} */}
                  </div>
                </Box>
              </Fragment>
            ) : null}
          </Box>
        </Box>
      ) : (
        <LoadingView />
      )}
    </Fragment>
  );
};

export default MyDetails;
