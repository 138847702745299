import CheckIcon from "@mui/icons-material/Check";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect, useState } from "react";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SECONDS = 5;
const TOTAL_PCT = 100;
const PROGRESS = TOTAL_PCT / SECONDS;

type Props = {
  isOpen: boolean;
  onComplete: () => void;
  onDismiss: () => void;
};

export default function CountdownModal(props: Props) {
  const [timeLeft, setTimeLeft] = useState(SECONDS);
  const [progress, setProgress] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setIsRunning(true);
      setTimeLeft((prevTime) => prevTime - 1);
      setProgress((prevProgress) =>
        prevProgress >= TOTAL_PCT ? 0 : prevProgress + PROGRESS
      );
    }, 1000);

    if (timeLeft === 0) {
      clearInterval(timer);
      setIsRunning(false); // Stop the timer

      if (props.onComplete) {
        props.onComplete(); // Fire the callback
      }
    }

    return () => clearInterval(timer);
  }, [timeLeft]);

  return (
    <React.Fragment>
      <Dialog
        open={props.isOpen}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <Box
          sx={{
            paddingTop: 3,
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              position: "relative",
              display: "inline-flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress
              variant="determinate"
              value={progress}
              size="6rem"
              color={progress === TOTAL_PCT ? "success" : "primary"}
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {progress === TOTAL_PCT ? (
                <CheckIcon color="success" />
              ) : (
                <Typography
                  variant="caption"
                  component="div"
                  sx={{ color: "text.secondary", fontSize: 24 }}
                >{`${Math.round(timeLeft)}`}</Typography>
              )}
            </Box>
          </Box>
        </Box>
        <DialogTitle sx={{ textAlign: "center" }}>
          {progress === TOTAL_PCT ? "Sent" : "Sending to production..."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{ textAlign: "center" }}
          >
            All designs are approved. We'll go straight to production and ship
            your order soon.
          </DialogContentText>
        </DialogContent>
        {progress < TOTAL_PCT ? (
          <DialogActions sx={{ justifyContent: "center", paddingBottom: 3 }}>
            <Button
              variant="contained"
              color="success"
              onClick={props.onComplete}
            >
              Accept
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
    </React.Fragment>
  );
}
