import Box from "@mui/material/Box";
import { useTranslation } from "next-i18next";
import { useState } from "react";

import { MailingAddressInput } from "@/types";
import theme from "@/utils/theme";
import ConfirmationDialog from "../ConfirmationDialog";
import {
  ActionsTypography,
  CardTypography,
  Separator,
  UserCard,
} from "./style";

interface IProps {
  address: MailingAddressInput;
  handleRemove: (address: MailingAddressInput) => void;
  handleEdit: (address: MailingAddressInput) => void;
  handleSetDefault: (address: MailingAddressInput) => void;
}

const UserAddressCard = ({
  address,
  handleRemove,
  handleEdit,
  handleSetDefault,
}: IProps) => {
  const { t } = useTranslation("UserAddressCard");
  const [type, setType] = useState<"delete" | "default">("delete");
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);

  const handleModalToggle = () =>
    setOpenConfirmationDialog(!openConfirmationDialog);

  return (
    <UserCard
      sx={{
        padding: { xs: 1.5, md: "12px 16px" },
        mb: { xs: 2, md: 0 },
        border: {
          xs: `1px solid ${theme.colors.greyPlaceholder}`,
          md: "none",
        },
        ":not(:last-child)": {
          borderBottom: `1px solid ${theme.colors.greyPlaceholder}`,
        },
      }}
    >
      <CardTypography
        sx={{ flex: { md: 1 }, width: { xs: "50%", md: "100%" }, mb: 1 }}
      >
        {address.firstName} {address.lastName}
      </CardTypography>
      <CardTypography
        sx={{ flex: { md: 1 }, width: { xs: "50%", md: "100%" }, mb: 1 }}
      >
        {address.address1}
      </CardTypography>
      <CardTypography
        sx={{ flex: { md: 1 }, width: { xs: "50%", md: "100%" }, mb: 1 }}
      >
        {address.address2}
      </CardTypography>
      <CardTypography
        sx={{ flex: { md: 1 }, width: { xs: "50%", md: "100%" }, mb: 1 }}
      >
        {address.city}
      </CardTypography>
      <CardTypography
        sx={{ flex: { md: 1 }, width: { xs: "50%", md: "100%" }, mb: 1 }}
      >
        {address.zip}
      </CardTypography>
      <CardTypography
        sx={{ flex: { md: 1 }, width: { xs: "50%", md: "100%" }, mb: 1 }}
      >
        {address.phone}
      </CardTypography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "0 6px",
        }}
      >
        <ActionsTypography onClick={() => handleEdit(address)}>
          {t("edit")}
        </ActionsTypography>
        <Separator />
        <ActionsTypography
          onClick={() => {
            handleModalToggle();
            setType("delete");
          }}
        >
          {t("remove")}
        </ActionsTypography>

        {/* {address.isDefault ? (
          <Box display="flex" alignItems="center" gap={0.5}>
            <Typography sx={{ fontSize: "14px" }}>
              {t("defaultAddress")}
            </Typography>
            <CheckCircleIcon sx={{ width: 16, height: 16 }} />
          </Box>
        ) : (
          <ActionsTypography
            onClick={() => {
              handleModalToggle();
              setType("default");
            }}
          >
            {t("setDefault")}
          </ActionsTypography>
        )} */}
      </Box>
      <ConfirmationDialog
        handleClose={handleModalToggle}
        handleDelete={() => {
          if (type === "delete") {
            handleRemove(address);
            handleModalToggle();
          } else {
            handleSetDefault(address);
            handleModalToggle();
          }
        }}
        title={type === "delete" ? t("deleteAddress") : t("setDefault")}
        open={openConfirmationDialog}
      >
        {type === "delete" ? (
          <>{t("confirmDeleteAddress")}</>
        ) : (
          <>{t("confirmDefaultAddress")}</>
        )}
      </ConfirmationDialog>
    </UserCard>
  );
};

export default UserAddressCard;
