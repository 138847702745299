export const encode = (
  model: string,
  id: string,
  params?: URLSearchParams
): string => {
  const paramsStr = params ? `?${params.toString()}` : "";

  return `gid://glide/${model}/${id}${paramsStr}`;
};

export const decode = (id: string): { model: string; id: string } => {
  var url = new URL(id);

  const pathname = url.pathname.replace(/^\//, "");
  const parts = pathname.split("/");

  if (parts.length === 2) {
    return {
      model: parts[0],
      id: parts[1],
    };
  }

  return {
    model: parts[2],
    id: parts[3],
  };
};

export const id = (gid: string) => decode(gid).id;
