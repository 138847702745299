import { uiState } from "@/contexts/uiState";
import { useUser } from "@/contexts/userContext";
import { websiteBaseUrl } from "@/utils/config";
import { decode } from "@/utils/gid";
import routes from "@/utils/routes";
import theme from "@/utils/theme";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useParams } from "next/navigation";
import { NextRouter, useRouter } from "next/router";

import React, { ReactNode, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import Logo from "../atoms/Logo";

import { ArrowBack } from "@mui/icons-material";
import dynamic from "next/dynamic";
import { findLineItemIndexByProofId } from "@/utils/proof-helpers";

const ProofPagination = dynamic(() => import("../templates/ProofPagination"), {
  ssr: false,
});

const links = [
  // { id: "link1", url: routes.home, label: "My Account" },
  { id: "link2", url: routes.orders, label: "Orders" },
  // { id: "link3", url: routes.designs, label: "My Designs" },
];

const settings = [
  { id: "link1", url: routes.orders, label: "My Account" },
  { id: "logout", label: "Logout" },
];

const Navbar: React.FC = ({
  toggleIsOpenNav,
}: {
  toggleIsOpenNav: () => void;
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const {
    dispatch,
    state,
    state: { canReorder, logoSquare },
  } = useUser();
  const params = useParams();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const router: NextRouter = useRouter();
  const [, setState] = useRecoilState(uiState);
  const ui = useRecoilValue(uiState);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [avatarContents, setAvatarContents] = useState<ReactNode>(null);

  const [anchorElFlat, setAnchorElFlat] = React.useState<null | HTMLElement>(
    null
  );
  const openFlatMenu = Boolean(anchorElFlat);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElFlat(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElFlat(null);
  };

  const currentLineItemIndex = findLineItemIndexByProofId(
    ui.proofPagination.proofId,
    ui.proofPagination.lineItems
  );

  const backBtnDisabled = currentLineItemIndex === 0;
  const nextBtnDisabled =
    currentLineItemIndex === ui.proofPagination.lineItems.length - 1;

  const handleThemeChange = () => {
    setState((prevState) => {
      return {
        ...prevState,
        isLight: !prevState.isLight,
      };
    });
  };

  useEffect(() => {
    setIsLoggedIn(state?.loggedIn);
  }, [state?.loggedIn]);

  const toggleMenu = () => setOpenMenu(!openMenu);

  const logOut = () => {
    dispatch({
      type: "logout",
    });
    window.location.href = `${websiteBaseUrl}/account/logout`;
  };

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleBackClick = () => {
    const nextIndex = currentLineItemIndex - 1;
    const foundLineItem = ui.proofPagination.lineItems[nextIndex];
    const nextProofId = decode(foundLineItem.proofs[0].id).id;
    if (params && foundLineItem && nextProofId) {
      router.push(`/orders/${params.id}/proofs/${nextProofId}`);
    }
  };

  const handleNextClick = () => {
    const nextIndex = currentLineItemIndex + 1;
    const foundLineItem = ui.proofPagination.lineItems[nextIndex];
    const nextProofId = decode(foundLineItem.proofs[0].id).id;
    if (params && foundLineItem && nextProofId) {
      router.push(`/orders/${params.id}/proofs/${nextProofId}`);
    }
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [faviconUrl, setFaviconUrl] = useState("/icon.png");

  useEffect(() => {
    if (logoSquare?.url) {
      setFaviconUrl(`${logoSquare?.url}?width=32&height=32`);
    }
  }, [logoSquare?.url]);

  useEffect(() => {
    if (state?.profile?.avatarUrl) {
      setAvatarContents(
        <img alt="My Account" src={state?.profile?.avatarUrl + "&s=40"} />
      );
    } else if (state?.profile?.firstName && state?.profile?.lastName) {
      setAvatarContents(
        state?.profile?.firstName
          ?.at(0)
          ?.concat(state?.profile?.lastName?.at(0) || "")
      );
    } else if (state?.profile?.firstName) {
      setAvatarContents(state?.profile?.firstName?.slice(0, 2));
    }
  }, [state?.profile?.avatarUrl]);

  // TODO: refactor: It has a centered logo

  const isProofingPage = params && params.proofId;

  return (
    <AppBar
      position="fixed"
      sx={{ backgroundColor: theme.colors.greenLightest, color: grey[900] }}
    >
      <Toolbar>
        <Box
          sx={{
            display: "grid",
            flexGrow: 1,
            gridTemplateColumns: "1fr auto 1fr",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columns: "1fr 1fr 1fr",
              }}
            >
              <Box>
                {isProofingPage ? (
                  <IconButton
                    onClick={() => {
                      router.push(`/orders/${params.id}`);
                    }}
                  >
                    <ArrowBack />
                  </IconButton>
                ) : (
                  <>
                    {isMobile ? (
                      <IconButton
                        edge="start"
                        color="inherit"
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        sx={{ mr: 2 }}
                        onClick={handleOpenNavMenu}
                      >
                        <MenuIcon />
                      </IconButton>
                    ) : (
                      <>
                        {links.map((links) => (
                          <MenuItem
                            key={links.id}
                            onClick={() => router.push(links.url)}
                          >
                            <Typography
                              textAlign="center"
                              sx={{ color: grey[900] }}
                            >
                              {links.label}
                            </Typography>
                          </MenuItem>
                        ))}
                      </>
                    )}
                  </>
                )}

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: "block",
                  }}
                >
                  {links.map((links) => (
                    <MenuItem
                      key={links.id}
                      onClick={() => router.push(links.url)}
                    >
                      <Typography textAlign="center" sx={{ color: grey[900] }}>
                        {links.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              {isProofingPage ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ProofPagination
                    currentLineItemIndex={currentLineItemIndex}
                    backBtnDisabled={backBtnDisabled}
                    nextBtnDisabled={nextBtnDisabled}
                    handleBackClick={handleBackClick}
                    handleNextClick={handleNextClick}
                  />
                </Box>
              ) : null}
            </Box>
          </Box>

          <Box
            sx={{
              justifyContent: "center",
            }}
          >
            <Typography variant="h6" component="div">
              <Logo />
            </Typography>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Stack direction="row" sx={{ flexGrow: 0 }}>
              {isProofingPage ? (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="body1" component="span">
                    Light
                  </Typography>
                  <Switch
                    checked={!ui.isLight}
                    onChange={handleThemeChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <Typography variant="body1" component="span">
                    Dark
                  </Typography>
                </Stack>
              ) : null}

              {!isProofingPage ? (
                <Box ml={2}>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar alt={state?.profile?.fullName}>
                        {avatarContents}
                      </Avatar>
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {settings
                      .filter((setting: { id: string; url?: string }) => {
                        if (
                          setting.id === "logout" &&
                          state?.loggedIn === false
                        ) {
                          return false;
                        }

                        return true;
                      })
                      .map((setting) => {
                        return (
                          <MenuItem
                            key={setting.id}
                            onClick={() => {
                              if (setting.id === "logout") {
                                dispatch({ type: "logout" });
                                return;
                              }

                              if (setting.url) {
                                router.push(setting.url);
                              }

                              handleCloseUserMenu();
                            }}
                          >
                            <Typography textAlign="center">
                              {setting.label}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                  </Menu>
                </Box>
              ) : null}
            </Stack>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
